import _ from 'lodash';
import { RequestConstants } from '../../request';
import {
  USERS_FETCH_KEY,
  ARCHIVE_USER,
  RESTORE_USER,
  RESEND_INVITE_USER,
  REMOVE_PERMITTED_ORGANIZATION_USER,
  restoreUserUrl,
  getUserUrl,
  archiveUserUrl,
  inviteUserUrl,
  shadowUserUrl,
  userRemoveApiUrl,
} from '../constants';

export const initialStore = {
  loaded: false,
  entities: {},
};

export const usersReducer = (store, action) => {
  store = store || initialStore;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(USERS_FETCH_KEY):
      return {
        loaded: true,
        entities: _.keyBy(action.content, 'id'),
      };
    case RequestConstants.getLoadingActionType(ARCHIVE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            archived: true,
            links: {
              restore: restoreUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getErrorActionType(ARCHIVE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            archived: false,
            links: {
              get: getUserUrl(action.content.organizationId, action.content.id),
              archive: archiveUserUrl(action.content.id),
              shadow: shadowUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getLoadingActionType(RESTORE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            archived: false,
            links: {
              get: getUserUrl(action.content.organizationId, action.content.id),
              archive: archiveUserUrl(action.content.id),
              shadow: shadowUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getErrorActionType(RESTORE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            archived: true,
            links: {
              restore: restoreUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getLoadingActionType(RESEND_INVITE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            links: {
              invite: inviteUserUrl(action.content.id),
              get: getUserUrl(action.content.organizationId, action.content.id),
              archive: archiveUserUrl(action.content.id),
              shadow: shadowUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getErrorActionType(RESEND_INVITE_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            links: {
              invite: inviteUserUrl(action.content.id),
              get: getUserUrl(action.content.organizationId, action.content.id),
              archive: archiveUserUrl(action.content.id),
              shadow: shadowUserUrl(action.content.id),
            },
          },
        },
      };
    case RequestConstants.getLoadingActionType(REMOVE_PERMITTED_ORGANIZATION_USER): {
      const { [action.content.id]: removedUser, ...remainingUsers } = store.entities;
      return {
        ...store,
        entities: remainingUsers,
      };
    }
    case RequestConstants.getErrorActionType(REMOVE_PERMITTED_ORGANIZATION_USER):
      return {
        ...store,
        entities: {
          ...store.entities,
          [action.content.id]: {
            ...store.entities[action.content.id],
            links: {
              remove_permitted_organization: userRemoveApiUrl(
                action.content.id, action.content.organizationId
              ),
            },
          },
        },
      };
    default:
      return store;
  }
};
